<template>
    <div>
        <AdminTop
            heading="Kom ihåg"
            add-new-url="/admin/reminders/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Titel
                </TableHeaderCell>

                <TableHeaderCell>
                    Datum
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(reminder, index) in reminders"
                    :key="reminder.id"
                    :to="`/admin/reminders/${reminder.id}`"
                    @delete="removeReminder(reminder.id, index)"
                >
                    <TableCell>
                        <div class="flex items-center">
                            <p class="text-gray-900 whitespace-no-wrap">
                                {{ reminder.title }}
                            </p>
                        </div>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ reminder.date }}
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import ReminderService from '@/services/admin/ReminderService';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell
    },

    data: () => ({
        reminders: []
    }),

    created () {
        this.getReminders();
    },

    methods: {
        async getReminders () {
            const res = await ReminderService.all();

            this.reminders = res.data;
        },

        async removeReminder (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await ReminderService.remove(id);

            if (res && res.status === 200) {
                const reminders = [...this.reminders];

                reminders.splice(index, 1);

                this.reminders = reminders;
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
